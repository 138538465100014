import {
  ChakraProvider,
  theme,
  Box,
  Container,
  Stack,
  Text,
  Link,
} from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

// pulled from navBar.js
const MenuItem = ({ children, isLast, to = '/' }) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
    >
      <Link href={to}>{children}</Link>
    </Text>
  );
};

export default function Footer() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <ChakraProvider theme={theme}>
      {isDesktop && (
        <Box>
          <Container
            as={Stack}
            maxW={'6xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}
          >
            <Box>
              <MenuItem>
                <img
                  style={{ width: 300, height: 100 }}
                  src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                  alt="wavelogo"
                />
              </MenuItem>
            </Box>
            <Box>
              <Stack direction="row" spacing={7}>
                <Link
                  color={'white'}
                  href="https://www.wavenightlife.com/company"
                  isExternal
                  style={{ fontSize: 28 }}
                >
                  About
                </Link>
                <Link
                  color={'white'}
                  href="https://www.wavenightlife.com/contact"
                  isExternal
                  style={{ fontSize: 28 }}
                >
                  Contact
                </Link>
                <Link
                  color={'white'}
                  href="https://www.wavenightlife.com/terms-and-conditions"
                  isExternal
                  style={{ fontSize: 28 }}
                >
                  Legal
                </Link>
              </Stack>
            </Box>
          </Container>
        </Box>
      )}
      {(isMobile || isTablet) && (
        <Box>
          <Container
            as={Stack}
            maxW={'6xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}
          >
            <Box>
              <Stack direction="row" spacing={7}>
                <Link
                  color={'white'}
                  href="https://www.wavenightlife.com/company"
                  isExternal
                  style={{ fontSize: 28 }}
                >
                  About
                </Link>
                <Link
                  color={'white'}
                  href="https://www.wavenightlife.com/contact"
                  isExternal
                  style={{ fontSize: 28 }}
                >
                  Contact
                </Link>
                <Link
                  color={'white'}
                  href="https://www.wavenightlife.com/terms-and-conditions"
                  isExternal
                  style={{ fontSize: 28 }}
                >
                  Legal
                </Link>
              </Stack>
            </Box>
          </Container>
        </Box>
      )}
    </ChakraProvider>
  );
}
